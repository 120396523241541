.time-picker {
  input[type='range'] {
    pointer-events: none;
    outline: none;
  }
  input[type='range']::-webkit-slider-thumb {
    pointer-events: all;
    cursor: pointer;
    z-index: 1;
  }
  input[type='range']::-moz-range-thumb {
    pointer-events: all;
    cursor: pointer;
    z-index: 1;
  }
  input[type='range']::-moz-range-track {
    z-index: 0;
  }
  input[type='range']::-webkit-slider-runnable-track {
    z-index: 0;
  }

  input[type='range'].start-time,
  input[type='range'].end-time {
    width: 100%;
    height: 15px;
  }
  input[type='range'].end-time {
    margin-top: -15px;
  }
}
