$font-dark: #212a2f;
$font-light: #6f7277;

.feedback {
  $modal-backdrop-opacity: 0.7;

  font-family: 'Lato', Helvetica, Arial, sans-serif;
  color: $font-dark;

  .modal-content {
    border-radius: 10px;
  }

  .modal-header {
    border-bottom: 0px;
    padding: 15px 15px 0px;
  }
  .close {
    opacity: 1;
    font-size: 35px;
    font-weight: 300;
    color: color('grey-90');
  }

  .modal-body {
    padding: 10px 50px;
  }

  h1 {
    color: color('grey-100');
    @extend .text-center;
    @extend .font-size-27;
    @extend .normal;
    margin-top: 0px;
    margin-bottom: 55px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    color: color('grey-90');
    @extend .text-left;
    @extend .font-size-20;
    @extend .normal;
    margin-top: 0px;
    margin-bottom: 25px;
  }

  .btn-group {
    .btn {
      background-color: white;
    }
    .btn:first-child:not(:last-child),
    .btn:not(:first-child):not(:last-child),
    .btn:last-child {
      color: color('grey-40');
      font-size: 23px;
      padding: 2px 12px;
      border-radius: 50px;
      border: 5px color('grey-40') solid;
      line-height: 35px;
      @extend .bold;
      margin: 0 -1.5px;
    }
    .btn:last-child {
      padding: 2px 6px;
    }
    .btn:hover,
    .btn:focus,
    .btn.focus,
    .btn-default:active,
    .btn-default.active {
      background-color: color('belize-hole');
      border-color: color('belize-hole') !important;
      color: white !important;
      outline: none;
      box-shadow: none !important;
    }
  }

  .modal-footer {
    @extend .text-left;
    padding: 10px 50px;
    margin-bottom: 25px;
    border-top: 0px;
    justify-content: center;
    button.primary {
      border-color: color('belize-hole');
      background-color: color('belize-hole');
      color: white;
      border-radius: 5px;

      &:active,
      &:focus,
      &:visited,
      &:hover {
        border-color: color('waterworld');
        background-color: color('waterworld');
        color: white;
      }
    }
  }
}
