@import 'src/stylesheets/typography';
@import 'src/stylesheets/variables/bootstrap';
@import 'src/stylesheets/variables/palette';

.campaignStatusBox {
  padding: 4px 8px;
  border-radius: $border-radius-base;
  text-align: center;
  line-height: 12px;
}
