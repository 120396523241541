/* tslint:disable */
// utility for supporting bs3 with rems from bs4
@function parseInt($n) {
  @return calc($n / ($n * 0 + 1));
}

// this has to be here because it's used before it's defined otherwise
$navbar-height: 3.125rem;

@import 'stylesheets/variables';
@import 'stylesheets/variables/bs4-variables';
@import 'bootstrap/scss/bootstrap.scss'; // bootstrap 4

//
//
// bootstrap 3 styling
//
//
@import 'bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Reset and dependencies
@import 'bootstrap-sass/assets/stylesheets/bootstrap/normalize';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/print';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/glyphicons';

// Core CSS
@import 'bootstrap-sass/assets/stylesheets/bootstrap/scaffolding';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/type';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/code';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/grid';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/tables';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/forms';

// Components
@import 'bootstrap-sass/assets/stylesheets/bootstrap/dropdowns';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/button-groups';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/input-groups';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/navs';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/pagination';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/pager';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/labels';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/badges';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/jumbotron';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/thumbnails';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/alerts';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/progress-bars';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/media';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/list-group';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/panels';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/wells';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/close';
@import 'bootstrap-five/scss/offcanvas.scss'; // bootstrap 4
@import 'bootstrap-five/scss/accordion.scss'; // bootstrap 4

// Components w/ JavaScript
@import 'bootstrap-sass/assets/stylesheets/bootstrap/modals';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/tooltip';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/popovers';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/carousel';

// Utility classes
@import 'bootstrap-sass/assets/stylesheets/bootstrap/utilities';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities';
//
//
// end bootstrap 3 styling
//
//

@import 'font-awesome-sass/assets/stylesheets/font-awesome';

// Utility Styles
@import 'stylesheets/typography';
@import 'stylesheets/utilities';

// Component Styles
@import 'stylesheets/alerts';
@import 'stylesheets/badges';
@import 'stylesheets/buttons';
@import 'stylesheets/email_preview';
@import 'stylesheets/feedback';
@import 'stylesheets/forms';
@import 'stylesheets/glyphs';
@import 'stylesheets/graph';
@import 'stylesheets/headway';
@import 'stylesheets/loyalty';
@import 'stylesheets/modals';
@import 'stylesheets/nav';
@import 'stylesheets/palette';
@import 'stylesheets/phone_preview';
@import 'stylesheets/reports';
@import 'stylesheets/tables';
@import 'stylesheets/tabs';
@import 'stylesheets/thanx_campaigns_info';
@import 'stylesheets/thanx_campaigns_landing';
@import 'stylesheets/thanx_campaigns_redeem';
@import 'stylesheets/thanx_campaigns_report';
@import 'stylesheets/thanx_campaigns_review';
@import 'stylesheets/uikit_overrides';
@import 'stylesheets/offcanvas';
@import 'stylesheets/accordion';

*:focus,
*:active {
  outline: none !important;
}

html {
  font-size: 16px; // basis for rem units: 1rem = 16px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $navbar-height;
  z-index: 900;
}

main {
  padding-top: $navbar-height;
  z-index: 700;
}

footer {
  width: 100%;
  height: $navbar-height;
  z-index: 800;
}

.a {
  color: color("belize-hole");
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: darken(color("belize-hole"), 15%);
  }
}

.confirm.modal-open .modal-header {
  border: none;
}

.confirm.modal-open .modal-footer {
  border: none;
}

.text-info-bright {
  color: color("belize-hole");
}

.text-muted-fade {
  color: color("grey-40");
}

.has-error .form-control-feedback {
  display: none;
}

// Hack around psuedo element nonsense in webkit (Safari etc.)
// https://github.com/twbs/bootstrap/issues/24219#issuecomment-370356871
.row:after,
.row:before {
  display: inline-block !important;
}
/* tslint:enable */
