@import 'stylesheets/variables/global';
@import 'stylesheets/variables/typography';

/* Font Family */
.font-lato {
  font-family: $font-lato;
}

/* Font Weight */
.light {
  font-weight: 200;
}
.normal {
  font-weight: 400;
}
.bold {
  font-weight: 700;
}
.bolder {
  font-weight: 800;
}
.extra-bold {
  font-weight: 900;
}
strong {
  @extend .bold;
}
.italic {
  font-style: italic;
}

/*
 * Heading Text
 */
.h1,
h1 {
  @extend .extra-bold;
  letter-spacing: $letter-spacing;
  font-size: 3rem;
}
.h2,
h2 {
  @extend .extra-bold;
  letter-spacing: $letter-spacing;
  font-size: 2rem;
}
.h3,
h3 {
  @extend .bold;
  letter-spacing: $letter-spacing * 2;
  font-size: 1.5rem;
}
.h4,
h4 {
  @extend .bold;
  letter-spacing: $letter-spacing * 2;
  font-size: 1.125rem;
}

/*
  Heading Numbers
 */
.hero-number {
  @extend .bold;
  font-size: 2rem;
}

/*
 * Body Text
 */
.body-text-1 {
  letter-spacing: $letter-spacing;
  font-size: 2rem;
}
.body-text-2 {
  letter-spacing: $letter-spacing;
  font-size: 1.125rem;
}
.body-text-3 {
  letter-spacing: $letter-spacing;
  font-size: 1rem;
}
.body-text-4 {
  letter-spacing: $letter-spacing;
  font-size: 0.875rem;
}
.body-text-5 {
  letter-spacing: $letter-spacing * 2;
  font-size: 0.75rem;
}
.body-text-6 {
  letter-spacing: $letter-spacing * 2;
  font-size: 0.625rem;
}

/*
 * Small Caps
 */
@mixin small-caps {
  @extend .bold;
  @extend .uppercase;
  letter-spacing: 0.125rem;
}

.small-caps {
  @include small-caps;
}

.small-caps-1 {
  @include small-caps;
  font-size: 0.875rem;
}
.small-caps-2 {
  @include small-caps;
  font-size: 0.75rem;
}
.small-caps-3 {
  @include small-caps;
  font-size: 0.625rem;
}

/*
 * Line Height
 */
.line-height-17 {
  line-height: 17px;
}
.line-height-23 {
  line-height: 23px;
}
.line-height-30 {
  line-height: 30px;
}
.line-height-40 {
  line-height: 40px;
}
.line-height-50 {
  line-height: 50px;
}

h5,
h6,
p {
  margin: 0 0 0.4em 0;
}

/*
 * Utilities
 */
.uppercase {
  text-transform: uppercase;
}
.letter-spacing-13 {
  letter-spacing: 0.13em;
}
.letter-spacing-025 {
  letter-spacing: 0.25em;
}

/* Deprecated */
$sizes: 8, 10, 12, 13, 14, 15, 16, 17, 18, 20, 22, 24, 25, 27, 28, 30, 32, 35,
  36, 40, 50, 64, 130;

@each $size in $sizes {
  .font-size-#{$size} {
    font-size: #{$size}px;
  }
}

@mixin heading-margin {
  margin-bottom: 0.4em;
}

.deprecated__h1 {
  @include heading-margin;
  font-size: 48px;
}
.deprecated__h2 {
  @include heading-margin;
  font-size: 40px;
}
.deprecated__h3 {
  @include heading-margin;
  font-size: 36px;
}
.deprecated__h4 {
  @include heading-margin;
  font-size: 32px;
}

.deprecated__body-text-2 {
  font-size: 28px;
}

.deprecated__body-text-3 {
  font-size: 24px;
}

.deprecated__small-caps-3 {
  font-size: 18px;
  @include small-caps;
}

.small-caps-4 {
  font-size: 14px;
  @include small-caps;
}

.small-caps-5 {
  font-size: 13px;
  @include small-caps;
}

.small-caps-6 {
  font-size: 10px;
  @include small-caps;
}

.small-caps-7 {
  font-size: 8px;
  @include small-caps;
}
