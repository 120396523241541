$spacer: 1rem;
$spacers: (
  xxs: (
    $spacer * 0.25,
  ),
  xs: (
    $spacer * 0.5,
  ),
  s: (
    $spacer * 1,
  ),
  m: (
    $spacer * 1.5,
  ),
  l: (
    $spacer * 2,
  ),
  xl: (
    $spacer * 3,
  ),
  xxl: (
    $spacer * 4,
  ),
  xxxl: (
    $spacer * 6,
  ),
);
