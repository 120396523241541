.btn.btn-borderless,
.btn.btn-borderless.btn-default {
  &,
  &:hover,
  &:active,
  &:visited,
  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
  }
}
.btn.btn-link {
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
}
.btn.btn-default {
  border: 1px solid color('grey-40');
  box-sizing: border-box;
  color: color('grey-60');
  &.white-border {
    border-color: white;
  }
}
.btn {
  border-width: 1px;
  padding-top: 6px;
  padding-bottom: 7px;
  &:focus,
  &:active {
    outline: none;
  }
  &.btn-info {
    background-color: transparent;
    border: 1px solid color('belize-hole');
    color: color('belize-hole');
    &:hover {
      background-color: fade-out(color('belize-hole'), 0.9);
    }
  }
  &.btn-inverted {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    &:hover {
      background-color: fade-out(white, 0.9);
    }
  }
  &.btn-lg,
  &.btn-large {
    font-size: 16px;
    padding-top: 12px;
    padding-bottom: 13px;
  }
  &.btn-xl,
  &.btn-xlarge {
    font-size: $font-size-large;
    font-weight: 700;
    padding-top: 13px;
    padding-bottom: 14px;
  }
  &.btn-huge {
    @extend .font-size-20;
    @extend .bold;
    padding-top: 17px;
    padding-bottom: 18px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.btn-group {
  .btn:first-child:not(:last-child),
  .btn:not(:first-child):not(:last-child),
  .btn:last-child {
    color: color('grey-40');
    padding: 2px 12px;
    border: 1px color('grey-40') solid;
    line-height: 35px;
    font-size: 14px;
    height: 48px;
  }
  .btn:last-child {
    padding: 2px 6px;
  }
  .btn-default.active {
    background-color: color('belize-hole');
    border-color: color('belize-hole') !important;
    color: white !important;
    outline: none;
    box-shadow: none;
  }
  .btn.btn-default:not(.active) {
    &:hover,
    &:active,
    &:visited,
    &:focus {
      outline: 0;
      background-color: white;
    }
  }
}
