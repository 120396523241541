// Classes
.tooltip-inner {
  text-align: left !important;
  padding: 20px !important;
  max-width: 300px !important;
  background-color: color('grey-10') !important;
  color: color('grey-60') !important;
  box-shadow: 0px 3px 5px 0px color('grey-60');
  border-radius: 4px !important;
}

.tooltip.in {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.tooltip.top .tooltip-arrow {
  border-top-color: color('grey-10') !important;
  filter: drop-shadow(0px 3px 1px color('grey-60'));
}

.tooltip.top-left .tooltip-arrow {
  border-top-color: color('grey-10') !important;
  filter: drop-shadow(0px 3px 1px color('grey-60'));
}

.tooltip.top-right .tooltip-arrow {
  border-top-color: color('grey-10') !important;
  filter: drop-shadow(0px 3px 1px color('grey-60'));
}

.tooltip.right .tooltip-arrow {
  border-right-color: color('grey-10') !important;
  filter: drop-shadow(-1px 3px 1px color('grey-60'));
}

.tooltip.left .tooltip-arrow {
  border-left-color: color('grey-10') !important;
  filter: drop-shadow(2px 3px 1px color('grey-60'));
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: color('grey-10') !important;
  filter: drop-shadow(0px -1px 0px color('grey-30'));
}

.tooltip.bottom-left .tooltip-arrow {
  border-bottom-color: color('grey-10') !important;
  filter: drop-shadow(0px -1px 0px color('grey-30'));
}

.tooltip.bottom-right .tooltip-arrow {
  border-bottom-color: color('grey-10') !important;
  filter: drop-shadow(0px -1px 0px color('grey-30'));
}
