@import 'stylesheets/variables/global';

/* Text Alignment */
.text-center-xs {
  text-align: center;

  @media (min-width: $screen-xs-min) {
    text-align: inherit;
  }
}

.text-center-sm {
  text-align: center;

  @media (min-width: $screen-sm-min) {
    text-align: inherit;
  }
}

.text-center-md {
  text-align: center;

  @media (min-width: $screen-md-min) {
    text-align: inherit;
  }
}

.text-right-sm {
  @media (min-width: 768px) {
    text-align: right;
  }
}

.text-left-sm {
  @media (min-width: 768px) {
    text-align: left;
  }
}

.text-left-md {
  text-align: inherit;

  @media (min-width: $screen-md-min) {
    text-align: left;
  }
}

.text-right-md {
  text-align: inherit;

  @media (min-width: $screen-md-min) {
    text-align: right;
  }
}

.text-right {
  text-align: right;
}

/* Text Decoration */
.no-underline {
  text-decoration: none !important;
}
.truncated {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.letter-spacing-1 {
  letter-spacing: 1px;
}
abbr {
  text-decoration-style: dashed;
  text-decoration-line: underline;
  text-decoration-color: inherit;
  cursor: help;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* print specfic */
@media print {
  .background-print {
    background-color: black !important;
  }

  .hidden-print {
    display: none !important;
  }

  .padding-none-print {
    padding: 0px !important;
  }
  .padding-top-none-print {
    padding-top: 0px !important;
  }
  .padding-bottom-none-print {
    padding-bottom: 0px !important;
  }

  .relative-print {
    position: relative;
  }
}

/* Display */
.display-table {
  display: table;
}
.display-table-row {
  display: table-row;
}
.display-table-cell {
  display: table-cell;
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}

@media not print {
  .hidden-screen {
    display: none !important;
  }
}

/* Position */
.absolute {
  position: absolute;
}
.absolute-md {
  @media (min-width: $screen-md-min) {
    position: absolute;
  }
}
.relative {
  position: relative;
}
.fixed {
  position: fixed;
}

/* Size */
.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}

$widths: 10, 20, 25, 33, 40, 50, 60, 66, 75, 80, 90, 95, 99, 100;
@each $width in $widths {
  $widthPercent: $width * 1%;

  .width-#{$width} {
    width: #{$widthPercent};
  }

  .max-width-#{$width} {
    max-width: #{$widthPercent};
  }
}

.max-width-0 {
  max-width: 0;
}

.full-width-on-xs {
  @media (max-width: $screen-xs-max) {
    width: 100%;
    max-width: 100%;
  }
}

.full-width-on-sm {
  @media (max-width: $screen-sm-max) {
    width: 100%;
    max-width: 100%;
  }
}

.height-100-vh {
  height: 100vh;
}

.full-height-without-navs {
  @media (min-width: $screen-md-min) {
    height: calc(100vh - 130px);
  }
}

/* Border */
$borderRadiusSizes: 2, 5, 10;
@each $borderRadiusSize in $borderRadiusSizes {
  $sizePx: $borderRadiusSize * 1px;

  .border-radius-#{$borderRadiusSize} {
    border-radius: #{$sizePx};
  }
  .border-radius-#{$borderRadiusSize}-bottom {
    border-radius: 0 0 #{$sizePx} #{$sizePx};
  }
  .border-radius-#{$borderRadiusSize}-top {
    border-radius: #{$sizePx} #{$sizePx} 0 0;
  }
  .border-radius-#{$borderRadiusSize}-right {
    border-radius: 0 #{$sizePx} #{$sizePx} 0;
  }
  .border-radius-#{$borderRadiusSize}-left {
    border-radius: #{$sizePx} 0 0 #{$sizePx};
  }
}

$borderSizes: 1, 2, 4;
@each $borderSize in $borderSizes {
  $sizePx: $borderSize * 1px;

  .border-#{$borderSize} {
    border-width: #{$sizePx};
    border-style: solid;
  }
  .border-top-#{$borderSize} {
    border-top-width: #{$sizePx};
    border-top-style: solid;
  }
  .border-bottom-#{$borderSize} {
    border-bottom-width: #{$sizePx};
    border-bottom-style: solid;
  }
  .border-right-#{$borderSize} {
    border-right-width: #{$sizePx};
    border-right-style: solid;
  }
  .border-left-#{$borderSize} {
    border-left-width: #{$sizePx};
    border-left-style: solid;
  }
}

.border-round {
  border-radius: 50%;
}
.border-0 {
  border-width: 0px;
}
.border-none {
  border: none !important;
}

/* Cursor */
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-help {
  cursor: help;
}
.cursor-default {
  cursor: default;
}

/* Opacity */
.opacity-25 {
  opacity: 0.25;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-75 {
  opacity: 0.75;
}

/* Animation */
.fade-in {
  animation: fadein-keyframes 1.5s ease-in;
}

@keyframes fadein-keyframes {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.stretch {
  transition: max-height 2s ease-out;
  height: auto;
  max-height: 600px;
  &.flat {
    max-height: 0px;
  }
}

/* Filter */
.grayscale {
  filter: grayscale(100%);
}

/* Flipping */
.flip-horiz {
  transform: scale(-1, 1);
}

.flip-vert {
  transform: scale(1, -1);
}

/* Positioning */
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.top-0 {
  top: 0;
}
.bottom-0 {
  bottom: 0;
}

/* Overflow */
.overflow-y {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

/* Modals */
.modal-container {
  position: relative;
}
.modal-container .modal,
.modal-container .modal-backdrop {
  position: absolute;
}

/* Nudge */
.nudge-down {
  top: 1px;
}
.nudge-up {
  top: -1px;
}
.nudge-down-2 {
  top: 2px;
}
.nudge-up-2 {
  top: -2px;
}
.nudge-right {
  left: 1px;
}
.nudge-left {
  left: -1px;
}
.nudge-right-2 {
  left: 2px;
}
.nudge-left-2 {
  left: -2px;
}

/* Alignment */
.vertical-sub {
  vertical-align: sub;
}
.vertical-top {
  vertical-align: top;
}
.vertical-middle {
  vertical-align: middle;
}

/* Images */
.contain {
  object-fit: contain;
}

.cover {
  object-fit: cover;
}

/* Flexbox Woooooo! */
.display-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-left {
  justify-content: flex-start;
}

/* children move to the upper left */
.flex-start {
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-align-center {
  align-items: center;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-align-baseline {
  align-items: baseline;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-start {
  align-items: start;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-all-children-even {
  & > * {
    flex: 1;
  }
}

@for $value from 0 through 5 {
  .flex-grow-#{$value} {
    flex-grow: #{$value};
  }

  .flex-#{$value} {
    flex: #{$value};
    /* Child elements of flexed containers must have a max-width
    *  to prevent IE11 text spill */
    * {
      max-width: 100%;
    }
  }
}

// Flexbox grid
.flex-col-3 {
  width: 25%;
  flex-shrink: 0;
}

.flex-col-6 {
  width: 50%;
  flex-shrink: 0;
}

.z0up {
  z-index: 0;
}

.z1up {
  z-index: 1;
}

.zmodal-background {
  z-index: $zindex-modal-background;
}

.modal-open .popover {
  z-index: $zindex-popover-modal;
}

////////////////////
// Deprecated
////////////////////

/* Padding */
@each $direction in $directions {
  // Deprecated
  .padding-#{$direction}-none {
    padding-#{$direction}: 0 !important;
  }
}

@each $size-name, $size in $sizes {
  // Deprecated
  .padding-#{$size-name} {
    padding: #{$size};
  }
}

@each $direction in $directions {
  @each $size-name, $size in $sizes {
    .padding-#{$direction}-#{$size-name} {
      padding-#{$direction}: #{$size};
    }
  }
}

// Deprecated
.padding-none {
  padding: 0 !important;
}

// Deprecated
.padding-left-large-sm {
  @media (min-width: 768px) {
    padding-left: $size-large;
  }
}

// Deprecated
.padding-left-extra-large-md {
  @media (min-width: $screen-md-min) {
    padding-left: $size-extra-large;
  }
}

// Deprecated
.padding-left-huge-md {
  @media (min-width: $screen-md-min) {
    padding-left: $size-huge;
  }
}

// Deprecated
.padding-bottom-small-md {
  @media (min-width: $screen-md-min) {
    padding-bottom: $size-small;
  }
}

// Deprecated
.padding-bottom-none-md {
  @media (min-width: $screen-md-min) {
    padding-bottom: 0px;
  }
}

// Deprecated
.padding-large-sm {
  @media (min-width: $screen-sm-min) {
    padding: $size-large;
  }
}

// Deprecated
.padding-none-sm {
  @media (min-width: $screen-sm-min) {
    padding: 0px;
  }
}
// Deprecated
.padding-bottom-none-sm {
  @media (max-width: $screen-sm-min) {
    padding-bottom: 0px !important;
  }
}
// Deprecated
.padding-top-none-sm {
  @media (max-width: $screen-sm-min) {
    padding-top: 0px !important;
  }
}
// Deprecated
.padding-top-huge-md {
  @media (max-width: $screen-md-min) {
    padding-top: $size-huge;
  }
}
// Deprecated
.padding-top-medium-sm {
  @media (max-width: $screen-sm-min) {
    padding-top: $size-medium;
  }
}
// Deprecated
.padding-top-large-sm {
  @media (max-width: $screen-sm-min) {
    padding-top: $size-large;
  }
}
// Deprecated
.padding-top-huge-sm {
  @media (max-width: $screen-sm-min) {
    padding-top: $size-huge;
  }
}

/* Margin */
// Deprecated
.margin-none {
  margin: 0 !important;
}

@each $direction in $directions {
  // Deprecated
  .margin-#{$direction}-none {
    margin-#{$direction}: 0 !important;
  }
}

@each $size-name, $size in $sizes {
  // Deprecated
  .negative-margin-#{$size-name} {
    margin: -#{$size};
  }
  // Deprecated
  .margin-#{$size-name} {
    margin: #{$size};
  }
}

@each $direction in $directions {
  @each $size-name, $size in $sizes {
    // Deprecated
    .negative-margin-#{$direction}-#{$size-name} {
      margin-#{$direction}: -#{$size};
    }
    // Deprecated
    .margin-#{$direction}-#{$size-name} {
      margin-#{$direction}: #{$size};
    }
  }
}

// Deprecated
.margin-extra-large-md {
  @media (min-width: $screen-md-min) {
    margin: $size-extra-large;
  }
}
// Deprecated
.margin-top-extra-large-md {
  @media (min-width: $screen-md-min) {
    margin: $size-extra-large;
  }
}

// Deprecated
.margin-top-extra-large-xs {
  @media (min-width: $screen-xs-min) {
    margin-top: $size-extra-large;
  }
}

// Deprecated
.margin-top-huge-md {
  @media (min-width: $screen-md-min) {
    margin: $size-huge;
  }
}

// Deprecated
.margin-top-huge-xs {
  @media (min-width: $screen-xs-min) {
    margin-top: $size-huge;
  }
}

// Deprecated
.margin-none-on-xs {
  @media (max-width: $screen-xs-max) {
    margin: none !important;
  }
}
