@import 'stylesheets/variables/palette';
@import './select';
@import './time_range';

.form-group-lg {
  .form-control-feedback {
    line-height: 46px;
  }
  .input-group-addon {
    font-size: 20px;
    line-height: 1.33333;
    border-width: 1px;
    width: 48px;
    color: color('grey-60');
  }
}

.input-group {
  width: 100%;
}

.form-horizontal .form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.form-group-lg textarea.form-control {
  @extend .font-size-14;
  resize: none;
}

.form-group-lg input.form-control {
  @extend .font-size-16;
}

input[type='input'],
input[type='text'],
input[type='password'],
input[type='input'].form-control,
input[type='text'].form-control input[type='password'].form-control,
textarea.form-control,
.form-control {
  box-shadow: none;
  border-width: 1px;
  height: 48px;
  @extend .font-size-14;
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    color: color('grey-40');
    border-color: color('grey-20');
  }
}

// reset input height for baseweb inputs
div[data-baseweb] {
  input:-webkit-autofill {
    height: auto;
  }
}

.form-group.form-group-lg .control-label {
  @extend .font-size-14;
  @extend .bold;
  @extend .margin-bottom-small;
  @extend .full-width;
}

.control-description {
  color: $text-muted;
}

.has-error {
  .control-description {
    color: $state-danger-text;
  }
  .text-danger.error-message {
    margin-top: 5px;
    @extend .font-size-14;
  }
  input[type='input'],
  textarea,
  input[type='text'],
  input[type='input'].form-control,
  textarea.form-control,
  input[type='password'],
  input[type='password'].form-control,
  input[type='text'].form-control,
  .form-control {
    color: color('cayenne-50');
    border-color: color('cayenne-50');
    &:focus {
      box-shadow: none;
    }
  }
  .fake-input {
    color: $state-danger-text;
    border-color: $state-danger-border;
    span:last-child {
      background-color: $state-danger-bg;
      color: $state-danger-text;
    }
  }
  .wysiwygContents {
    border-color: $state-danger-text !important;
  }
}

.has-warning {
  .control-description {
    color: $state-warning-text;
  }
}

.has-success {
  .control-description {
    color: $state-success-text;
  }
}

label {
  font-weight: normal;
  font-size: 1.2em;
  padding-top: 0px !important;
}

label > input[type='checkbox'] {
  margin-right: 15px;
  margin-top: 0.1em;
  vertical-align: top;
}

.form-group {
  .wysiwygEditor {
    font-family: inherit;
    border: none;
    background-color: transparent;
    position: relative;
    .public-DraftEditorPlaceholder-root {
      @extend .font-size-14;
      color: color('grey-50');
    }
    .wysiwygToolbar {
      text-align: right;
      margin: 0;
      border-bottom: none;
      padding-top: 0;
    }
    .wysiwygContents {
      border: 1px solid color('grey-40');
      border-radius: $border-radius-base;
      padding: 15px;
      min-height: 288px;
      .DraftEditor-root {
        height: 288px;
      }
    }
    &.short {
      .wysiwygContents {
        min-height: 140px;
        height: 140px;
        overflow-y: auto;
        .DraftEditor-root {
          height: 110px;
        }
      }
    }
  }
}

.fake-input {
  border: 1px solid color('grey-40');
  border-radius: $border-radius-base;
  @extend .font-size-14;
  color: $input-color;
  cursor: pointer;
  display: table;
  width: 100%;
  table-layout: fixed;
  span {
    display: table-cell;
  }
  span:first-child {
    width: 75%;
    padding: 12px 16px 13px;
  }
  span:last-child {
    background-color: $input-group-addon-bg;
    border-left: 2px solid color('grey-40');
    padding: 10px 0 9px;
    margin-top: -10px;
    width: 25%;
    text-align: center;
  }
}

.react-select__container {
  .react-select__control {
    &:hover {
      border-color: color('belize-hole');
    }
  }
}

.react-select__value-container--hasValue .hiddenOnSelected {
  display: none;
}

.color-picker-container {
  position: absolute;
  z-index: $zindex-popover;
  .click-handler {
    pointer-events: none;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='your_transparent.png', sizingMethod='scale');
    background: none !important;
  }
}

.input-group.datepicker {
  z-index: inherit;
  input {
    @extend .font-size-14;
    @extend .cursor-pointer;
    z-index: inherit;
  }
}

.unlayer-wrapper {
  left: 50px;
  right: 50px;

  iframe {
    min-width: 100% !important;
  }
}
