@import 'stylesheets/variables/bs4/spacing';

$grid-breakpoints: (
  xs: 0,
  sm: 704px,
  md: 992px,
  lg: 1400px,
  xl: 1600px,
) !default;

$container-max-widths: (
  sm: 752px,
  md: 960px,
  lg: 1368px,
  xl: 1500px,
) !default;

$font-size-large: 1.125rem;

$input-btn-font-size: 0.875rem !default;

$grid-gutter-width: 32px !default;

$line-height-base: 1.25 !default;

$nav-link-padding-y: 1rem !default;
$nav-link-padding-x: 1.3rem !default;
$navbar-nav-link-padding-x: 1.3rem !default;
$navbar-dark-color: color('grey-30') !default;

$input-height: 1rem !default;
$input-height-sm: 1rem !default;
$input-height-lg: 1rem !default;

$gray-100: color('grey-10') !default;
$gray-200: color('grey-20') !default;
$gray-300: color('grey-30') !default;
$gray-400: color('grey-40') !default;
$gray-500: color('grey-50') !default;
$gray-600: color('grey-60') !default;
$gray-700: color('grey-70') !default;
$gray-800: color('grey-80') !default;
$gray-900: color('grey-90') !default;
$primary: color('belize-hole') !default;
$secondary: $gray-600 !default;
$success: color('spearmint-50') !default;
$info: color('belize-hole') !default;
$warning: color('egg-yolk') !default;
$danger: color('cayenne-50') !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

// backwards compatibility for .fade.in
.fade.in {
  opacity: 1 !important;
}

.modal-backdrop {
  &.in {
    opacity: 0.5 !important;
  }
}

// support for bs4 modals with old-style css
.modal-backdrop.fade.show {
  opacity: 0.5;
}

.modal.show.fade .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
