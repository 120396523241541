/* Sizes */
$size-huge: 48px;
$size-extra-large: 32px;
$size-large: 24px;
$size-medium: 16px;
$size-small: 8px;
$size-tiny: 4px;
$size-micro: 2px;

$sizes: (
  'micro': $size-micro,
  'tiny': $size-tiny,
  'small': $size-small,
  'medium': $size-medium,
  'large': $size-large,
  'extra-large': $size-extra-large,
  'huge': $size-huge,
);

$directions: 'top', 'right', 'left', 'bottom';

/* Fonts Families */
$font-lato: 'Lato', Helvetica, Arial, sans-serif;
