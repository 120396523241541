$category-targeted: #8e44ad;
$category-message: #f39c12;
$category-automated: #13c1bf;

$deprecated-colors: (
  'alizarin': #e74c3c,
  'blue-chrome-20': #aeb6bf,
  'blue-chrome-30': #8692a0,
  'blue-chrome-40': #5d6e7f,
  'blue-chrome-50': #34495e,
  'blue-chrome-60': #2a3c4f,
  'blue-chrome-70': #1e2e3f,
  'blue-chrome-80': #0e1e2e,
  'blue-chrome-90': #000317,
  'blue-chrome': #34495e,
  'blue-steel': #252f3a,
  'category-automated-10': #d1ecf0,
  'category-automated-20': #a5dcdf,
  'category-automated-40': #51c3c5,
  'category-automated-50': $category-automated,
  'category-automated': $category-automated,
  'category-message-10': mix(white, $category-message, 90%),
  'category-message-20': mix(white, $category-message, 70%),
  'category-message-40': mix(white, $category-message, 30%),
  'category-message-50': $category-message,
  'category-message': $category-message,
  'category-targeted-10': mix(white, $category-targeted, 90%),
  'category-targeted-20': mix(white, $category-targeted, 70%),
  'category-targeted-40': mix(white, $category-targeted, 30%),
  'category-targeted-50': $category-targeted,
  'category-targeted': $category-targeted,
  'cayenne-60': #c5522c,
  'emerald': #2ecc71,
  'gold': #cbb44e,
  'grey-100': #363436,
  'grey-60': #848c98,
  'grey-80': #495462,
  'grey': color('grey-50'),
  'jungle': #1abc9c,
  'midnight-blue': #131a21,
  'peter-river': #338fcc,
  'spearmint-20': #a5dcdf,
  'spearmint-60': #229d9d,
  'sun-flower': #f1c40f,
  'waterworld': #01568e,
);

$supported-colors: (
  'nephritis': #27ae60,
  'nephritis-50': #27ae60,
  'nephritis-10': #e9f6ef,
  'nephritis-20': #d6f5e4,
  'wisteria': #8e44ad,
  'egg-yolk': #e7b41a,
  'egg-yolk-50': #e7b41a,
  'egg-yolk-10': #faf0d1,
  'orange': #f39c12,
  'belize-hole': #0073bf,
  'belize-hole-50': #0073bf,
  'belize-hole-10': #e5f0f8,
  'grey-90': #36424e,
  'grey-70': #6a747f,
  'grey-50': #a4adb6,
  'grey-40': #c2c8ce,
  'grey-30': #d6dade,
  'grey-20': #e9eaec,
  'grey-10': #f2f4f5,
  'grey-05': #f8f9fa,
  'spearmint': #13c1bf,
  'spearmint-50': #13c1bf,
  'spearmint-50-hover': #15d6d4,
  'spearmint-10': #e3f7f7,
  'spearmint-05': #f1fbfb,
  'cayenne': #f26335,
  'cayenne-50': #f26335,
  'cayenne-10': #fbdfd6,
  'white': #ffffff,
  'black': #000000,
  'transparent': transparent,
);

$colors: map-merge($deprecated-colors, $supported-colors);

@function color($color) {
  @if map-has-key($colors, $color) {
    @return map-get($colors, #{'' + $color});
  }
  @warn "The color #{$color} does not exist.";
  @return null;
}

:export {
  @each $key, $value in $supported-colors {
    #{"" + $key}: #{'' + $value};
  }
}
