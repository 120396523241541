#alerts {
  width: 100%;

  .container,
  .container-fluid {
    padding: 0;
  }

  .alert {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .alert-flex-1 {
    flex: 1;
  }

  .reload-button {
    background: #fbdfd6;
    color: #f26335;
    border: 2px solid #f26335;
    height: 35px;
    font-weight: bold;
  }
}
