@import 'stylesheets/variables/palette';

.email-preview-container {
  max-width: 660px;
  padding: 30px;
  background-color: color('grey-10');
  margin: 0;
}

.email-preview {
  font-family: 'Helvetica', 'Arial', sans-serif;
  max-width: 600px;
  background-color: white;
  margin: 0 auto;
  color: #505050;
  pointer-events: none;
  .hero-image {
    height: auto;
    width: 100%;
    border: none;
  }
  .header-text {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: normal;
    font-weight: bold;
    padding: 14px 18px 6px;
  }
  .header-image {
    padding: 9px 18px;
    img {
      max-width: 100px;
    }
  }
  .content {
    margin-top: 0px;
    font-size: 16px;
    line-height: 24px;
    padding: 9px 18px;
    p {
      margin-bottom: 10px;
    }
  }
  .bottom-content {
    margin-bottom: 36px;
  }
  .bottom-container {
    padding: 18px;
    .btn {
      border-radius: 3px;
      border: none;
      padding: 18px 20px;
      letter-spacing: 1px;
      white-space: normal;
      line-height: 16px;
      font-size: 16px;
    }
  }
  .email-footer {
    font-family: arial, sans-serif;
    padding: 74px 20px 30px;
    background-color: #f2f4f5;
    color: #808080;
    font-size: 12.8px;
    line-height: 20.8px;
  }
  .reward-content {
    padding: 9px 18px;
    text-align: center;
    .text-container {
      padding-bottom: 18px;
    }
    .box {
      padding: 18px;
      margin-bottom: 16px;
      .title {
        font-size: 24px;
        line-height: 52px;
      }
      .subtitle {
        margin: 9px 0 11px;
        font-size: 16px;
        line-height: 24px;
      }
      .btn {
        border-radius: 3px;
        border: none;
        padding: 20px;
        letter-spacing: 1px;
        white-space: normal;
        line-height: 16px;
        font-size: 16px;
        width: 100%;
      }
    }
    .fine-print {
      font-style: italic;
      font-size: 12px;
      line-height: 15px;
      color: #a9a9a9;
    }
  }
}
